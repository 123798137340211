/* eslint-disable */
/* tslint:disable */

/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */


export enum AttachmentType {
    Other = "Other",
    EligibilityReport = "EligibilityReport",
    DeductibleMet = "DeductibleMet",
    EmailToDoctor = "EmailToDoctor",
    DeductibleNotMet = "DeductibleNotMet",
    PracticeLogo = "PracticeLogo",
    AutoPostedEraReport = "AutoPostedEraReport",
}

export interface MargeInsuranceModel {
    insuranceIds?: (number | null | undefined)[] | null;
    /** @format int32 */
    baseInsuranceId?: number | null;
}
export interface ApiInsuranceMapGroupedDto {
    insuranceName?: string | null;
    insuranceCode?: string | null;
    ids?: number[] | null;
    status?: GeneralStatus;
    /** @format int64 */
    id?: number | null;
}

export interface ApiInsuranceMapGroupedDtoListResponseModel {
    list?: ApiInsuranceMapGroupedDto[] | null;
    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}


export interface ApiInsuranceMapDto {
    insurance?: InsurancesDto;
    /** @format int32 */
    insuranceId?: number;
    /** @format int64 */
    sourceUniqueId?: number | null;
    sourceUniqueCode?: string | null;
    isReviewed?: boolean;
    status?: GeneralStatus;
    /** @format int64 */
    id?: number | null;
}
export interface MoveInsurancesModel {
    insuranceMapIds?: number[] | null;
    /** @format int32 */
    baseInsuranceId?: number | null;
    practiceIds?: number[] | null;
}

export interface BooleanModelResponse {
    model?: boolean;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface BooleanNullableModelResponse {
    model?: boolean | null;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ApiInsuranceMapDtoListResponseModel {
    list?: ApiInsuranceMapDto[] | null;
    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ApiInsuranceMapDtoModelResponse {
    model?: ApiInsuranceMapDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export enum Actions {
    Read = "read",
    Write = "write",
    Delete = "Delete",
    Update = "Update",
}

export class AddressDto {
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    country?: string | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export enum AllowanceType {
    Required = "Required",
    Allowed = "Allowed",
    Disallowed = "Disallowed",
}

export interface AuthorizationSettingsDto {
    /** @format int64 */
    specialtyId?: number | null;

    /** @format int32 */
    cptId?: number | null;

    /** @format int32 */
    insuranceId?: number | null;
    insurancePlanId?: number | null;
    needReferral?: boolean | null;
    needAuthrization?: boolean | null;
    cpt?: ProcedureCodesDto;
    specialty?: SpecialtiesDto;
    insurance?: InsurancesDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface AuthorizationSettingsDtoListResponseModel {
    list?: AuthorizationSettingsDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface AuthorizationSettingsDtoModelResponse {
    model?: AuthorizationSettingsDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface BooleanModelResponse {
    model?: boolean;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}


export interface ClaimValidateModel {
    procedures?: ProcedureModel[] | null;
    /** @format int64 */
    insurancePlanId?: number | null;
    /** @format int64 */
    insuranceId?: number | null;
    /** @format int64 */
    practiceId?: number | null;
    /** @format int64 */
    providerId?: number | null;
    /** @format int64 */
    profileId?: number | null;
    gender?: GenderAllowance;
    /** @format int64 */
    patientId?: number | null;
    /** @format date-time */
    visitDate?: string;
    /** @format int64 */
    posCodeId?: number | null;
    /** @format date-time */
    hospitalAdmissionDate?: string | null;
    hasAuthorization?: boolean | null;
    hasReferringDoctor?: boolean | null;
    diagnosisIds?: number[] | null;
    scrubResults?: ScrubResultDto[] | null;
    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}


export enum Gender {
    Male = "Male",
    Female = "Female",
    Unknown = "Unknown",
}

export enum GenderAllowance {
    Male = "Male",
    Female = "Female",
    Both = "Both",
}

export interface ClaimValidateModelListResponseModel {
    list?: ClaimValidateModel[] | null;
    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ClaimValidateModelModelResponse {
    model?: ClaimValidateModel;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ClaimValidationResponse {
    procedures?: ProcedureValidationResponse[] | null;
    insuranceId?: ValidationResponse;
    practiceId?: ValidationResponse;
    providerId?: ValidationResponse;
    dob?: ValidationResponse;
    gender?: ValidationResponse;
    visitDate?: ValidationResponse;
    posCodeId?: ValidationResponse;
    hospitalAdmissionDate?: ValidationResponse;
    hasAuthorization?: ValidationResponse;
    hasReferringDoctor?: ValidationResponse;
    diagnosisIds?: ValidationResponse;
    profileId?: ValidationResponse;
    procedureSort?: ValidationResponse;
    patientId?: ValidationResponse;
}


export interface ValidationResponse {
    success?: boolean;
    message?: string | null;
}

export interface ProcedureValidationResponse {
    procedureCodeId?: ValidationResponse;
    placeOfServiceId?: ValidationResponse;
    chargeAmount?: ValidationResponse;
    units?: ValidationResponse;
    dateOfService?: ValidationResponse;
    modifierIds?: ValidationResponse;
    diagPointers?: ValidationResponse;
    isJustifiableWriteOff?: ValidationResponse;
    age?: ValidationResponse;
    gender?: ValidationResponse;
    procedureType?: ValidationResponse;
    addOn?: ValidationResponse;
    missingModifiers?: ValidationResponse;
    disallowedModifiers?: ValidationResponse;
    unknownModifiers?: ValidationResponse;
    globalPeriod?: ValidationResponse;
    dailyLimit?: ValidationResponse;
    bundle?: ValidationResponse;
    writeOffMods?: ValidationResponse;
}

export interface PatientDto {
    /** @format int32 */
    practiceId?: number;
    name?: string | null;
    /** @format date-time */
    dob?: string | null;
    address1?: string | null;
    address2?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    homePhone?: string | null;
    workPhone?: string | null;
    cellPhone?: string | null;
    emailAddress?: string | null;
    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ClearingHousesDto {
    code?: string | null;
    name?: string | null;

    /** @format tel */
    phoneNumber?: string | null;

    /** @format email */
    emailAddress?: string | null;

    /** @format uri */
    website?: string | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ClearingHousesDtoListResponseModel {
    list?: ClearingHousesDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ClearingHousesDtoModelResponse {
    model?: ClearingHousesDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ColumnFilter {
    columnName?: string | null;
    value?: string | null;
}

export interface ContactRoleDto {
    /** @format int32 */
    contactId?: number | null;

    /** @format int64 */
    contactRoleTypeId?: number | null;
    practiceContact?: PracticeContactsDto;
    contactRoleType?: ContactRoleTypesDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ContactRoleTypesDto {
    roleName?: string | null;
    description?: string | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ContactRoleTypesDtoListResponseModel {
    list?: ContactRoleTypesDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ContactRoleTypesDtoModelResponse {
    model?: ContactRoleTypesDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export enum ContractRateType {
    Fixed = "Fixed",
    Percent = "Percent",
}

export enum ContractType {
    InNetwork = "InNetwork",
    OutOfNetwork = "OutOfNetwork",
}

export interface CorporationFeeSchedulesDto {
    /** @format int64 */
    corporationId?: number | null;

    /** @format int64 */
    feeScheduleId?: number | null;
    contractType?: ContractType;

    /** @format date-time */
    dateStart?: string | null;

    /** @format date-time */
    dateEnd?: string | null;
    status?: DateDependantStatus;
    feeSchedule?: FeeSchedulesDto;
    corporation?: CorporationsDto;

    /** @format int64 */
    id?: number | null;
}

export interface CorporationsDto {
    /** @format int32 */
    practiceId?: number | null;
    name?: string | null;
    code?: string | null;
    taxId?: string | null;
    npiNumber?: string | null;

    /** @format int64 */
    addressId?: number | null;
    practice?: PracticesDto;
    address?: AddressDto;
    cpt?: ProcedureCodesDto;
    profiles?: ProfilesDto[] | null;
    feeSchedules?: CorporationFeeSchedulesDto[] | null;
    feeScheduleOverrides?: FeeScheduleOverrideDto[] | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface CorporationsDtoListResponseModel {
    list?: CorporationsDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface CorporationsDtoModelResponse {
    model?: CorporationsDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export enum DailyLimitLineType {
    Single = "Single",
    Multiple = "Multiple",
}

export enum DateDependantStatus {
    Pending = "Pending",
    Active = "Active",
    Inactive = "Inactive",
    Deleted = "Deleted",
    Expired = "Expired",
}

export interface DiagnosisDto {
    code?: string | null;
    description?: string | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface DiagnosisDtoListResponseModel {
    list?: DiagnosisDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface DiagnosisDtoModelResponse {
    model?: DiagnosisDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface EhrDto {
    name?: string | null;
    description?: string | null;
    code?: string | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface EhrDtoListResponseModel {
    list?: EhrDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface EhrDtoModelResponse {
    model?: EhrDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface EmailDto {
    /** @format email */
    emailAddress?: string | null;
    isPrimary?: boolean;
    objectType?: string | null;

    /** @format int64 */
    id?: number | null;
    isDeactivated?: boolean;
    status?: GeneralStatus;
}

export interface FeeScheduleOverrideDto {
    /** @format int64 */
    corporationId?: number | null;

    /** @format int64 */
    profileContractId?: number | null;

    /** @format int64 */
    cptId?: number | null;

    /** @format double */
    amount?: number;
    contractType?: ContractType;
    corporation?: CorporationsDto;
    profileContract?: ProfileContractDto;

    modifierId?: number | null;

    modifier?: ModifiersDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface FeeScheduleOverrideDtoListResponseModel {
    list?: FeeScheduleOverrideDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface FeeScheduleOverrideDtoModelResponse {
    model?: FeeScheduleOverrideDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface FeeSchedulesDto {
    name?: string | null;
    description?: string | null;
    type?: FeeScheduleType;

    /** @format int64 */
    baseFeeScheduleId?: number | null;
    baseFeeSchedule?: FeeSchedulesDto;
    markupType?: MarkupType;

    /** @format double */
    markupAmount?: number | null;
    versions?: FeeScheduleVersionDto[] | null;
    corporationFeeSchedules?: CorporationFeeScheduleDto[] | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface BaseDto {
    id?: number | null;
    status?: GeneralStatus;
}

export interface FeeSchedulePricingDto extends BaseDto {
    feeScheduleVersionId?: number;
    feeScheduleVersion?: FeeScheduleVersionDto;
    cptId?: number;
    cpt?: ProcedureCodesDto;
    markupAmount?: number;
    pricingDetails?: FeeSchedulePricingDetailDto[];
}

export interface FeeSchedulePricingDetailDto extends BaseDto {
    feeSchedulePricingId?: number;
    feeSchedulePricing: FeeSchedulePricingDto;
    modifierId?: number;
    modifier: ModifiersDto;
    facilityPrice?: number;
    nonFacilityPrice?: number;

    overridePrice?: FeeSchedulePricingDetailDto;
}

export interface FeeScheduleVersionDto {
    id?: number | null;
    feeScheduleId?: number;
    feeSchedule?: FeeSchedulesDto;
    versionNumber?: number;
    dateStart?: Date;
    dateEnd?: Date;
    pricings?: FeeSchedulePricingDto[];
    status?: FeeScheduleStatus;
}

export enum FeeScheduleStatus {
    Pending = "Pending",
    Active = "Active",
    Inactive = "Inactive",
    Deleted = "Deleted",
    Expired = "Expired",
    Draft = "Draft"
}

export interface CorporationFeeScheduleDto {
    /** @format int64 */
    corporationId?: number | null;

    /** @format int64 */
    feeScheduleId?: number | null;
    contractType?: ContractType;

    /** @format date-time */
    dateStart?: string | null | Date | number;

    /** @format date-time */
    dateEnd?: string | null | Date | number;
    status?: DateDependantStatus;
    feeSchedule?: FeeSchedulesDto;
    corporation?: CorporationsDto;

    /** @format int64 */
    id?: number | null;
}

export enum MarkupType {
    Percent = "Percent",
    Amount = "Amount",
}

export interface FeeSchedulesDtoListResponseModel {
    list?: FeeSchedulesDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface FeeSchedulesDtoModelResponse {
    model?: FeeSchedulesDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export enum FeeScheduleType {
    Manual = "Manual",
    Medicare = "Medicare",
    Dynamic = "Dynamic"
}


export interface GeneralSettingsDto {
    /** @format int32 */
    globalPeriodDefaultModifierId?: number | null;

    /** @format int32 */
    globalPeriodEnmModifierId?: number | null;

    /** @format int32 */
    dailyLimitDefault?: number | null;

    /** @format int32 */
    dailyLimitDefaultModifierId?: number | null;
    dailyLimitDefaultLineType?: DailyLimitLineType;

    /** @format int32 */
    bundleDefaultModifierId?: number | null;

    /** @format int32 */
    bundleEnmModifierId?: number | null;

    /** @format int32 */
    procedureMaxDiagnisis?: number | null;
    globalPeriodDefaultModifierCode?: string | null;
    globalPeriodEnmModifierCode?: string | null;
    bundleDefaultModifierCode?: string | null;
    bundleEnmModifierCode?: string | null;
    dailyLimitDefaultModifierCode?: string | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface GeneralSettingsDtoListResponseModel {
    list?: GeneralSettingsDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface GeneralSettingsDtoModelResponse {
    model?: GeneralSettingsDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export enum GeneralStatus {
    Active = "Active",
    Inactive = "Inactive",
    Deleted = "Deleted",
}

export enum GlobalPeriodType {
    Same = "Same",
    All = "All",
}

export interface InsuranceContactDto {
    /** @format int32 */
    insuranceId?: number | null;
    firstName?: string | null;
    lastName?: string | null;

    /** @format int64 */
    phoneId?: number | null;

    /** @format int64 */
    emailId?: number | null;
    role?: string | null;
    phone?: PhoneDto;
    email?: EmailDto;
    insurance?: InsurancesDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface InsuranceContactDtoListResponseModel {
    list?: InsuranceContactDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface InsuranceContactDtoModelResponse {
    model?: InsuranceContactDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface InsurancePlansDto {


    /** @format int32 */
    insuranceId?: number | null;
    name?: string | null;
    code?: string | null;
    memberIdPrefix?: string | null;
    memberIdSuffix?: string | null;

    /** @format int64 */
    credentialingAddressId?: number | null;
    hasDeductible?: boolean | null;

    /** @format int32 */
    deductibleMinAge?: number | null;
    hasCopay?: boolean | null;
    hasCoInsurance?: boolean | null;
    hasOutOonBenefits?: boolean | null;
    isGoodPayerOon?: boolean | null;
    eligibilityPayorId?: string | null;

    /** @format int64 */
    eligibilityAddressId?: number | null;
    claimsPayorId?: string | null;
    statusPayorId?: string | null;

    /** @format int64 */
    statusAddressId?: number | null;

    /** @format int64 */
    clearingHouseId?: number | null;

    /** @format int32 */
    inNetworkFilingLimit?: number | null;

    /** @format int32 */
    outOfNetworkFilingLimit?: number | null;

    /** @format int32 */
    correctedClaimFilingLimit?: number | null;
    acceptsSecondaryClaimsE?: boolean | null;
    acceptsCorrectedClaimsE?: boolean | null;

    /** @format int64 */
    appealAddressId?: number | null;

    /** @format int64 */
    paymentAddressId?: number | null;
    hasEftOption?: boolean | null;
    eftInstructions?: string | null;
    hasEraOption?: boolean | null;
    eraInstructions?: string | null;
    insurance?: InsurancesDto;
    credentialingAddress?: AddressDto;
    eligibilityAddress?: AddressDto;
    statusAddress?: AddressDto;
    appealAddress?: AddressDto;
    paymentAddress?: AddressDto;
    planTypes?: InsurancePlanTypesDto[] | null;
    phones?: PhoneDto[] | null;
    emails?: EmailDto[] | null;
    eligibilityPhones?: PhoneDto[] | null;
    eligibilityEmails?: EmailDto[] | null;
    statusPhones?: PhoneDto[] | null;
    statusEmails?: EmailDto[] | null;
    appealPhones?: PhoneDto[] | null;
    appealEmails?: EmailDto[] | null;
    paymentPhones?: PhoneDto[] | null;
    paymentEmails?: EmailDto[] | null;
    webLinks?: WebLinksDto[] | null;
    profileContracts?: ProfileContractDto[] | null;
    specialtyAuthorizationSettings?: AuthorizationSettingsDto[] | null;
    procedureAuthorizationSettings?: AuthorizationSettingsDto[] | null;
    excludeBenefits?: PlanExcludeBenefitDto[] | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface InsurancePlansDtoListResponseModel {
    list: InsurancePlansDto[] | null;

    /** @format int64 */
    rowsCount: number;
    success: boolean;
    message: string | null;
    errors: string[] | null;
}

export enum PatientBillingType {
    Y = "Y",
    N = "N",
    P = "P",
    C = "C",
}

export interface InsurancePlansDtoModelResponse {
    model: InsurancePlansDto | null;
    success: boolean;
    message: string | null;
    errors: string[] | null;
}

export interface InsurancePlanTypesDto {
    /** @format int64 */
    insuarncePlanId?: number;

    /** @format int64 */
    planTypeId?: number;
    insurancePlan?: InsurancePlansDto;
    planType?: PlanTypeDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface InsurancesDto {
    name?: string | null;
    code?: string | null;
    
    

    /** @format int64 */
    addressId?: number | null;
    isIpa?: boolean;
    isTemp?: boolean;

    /** @format int32 */
    parentInsuarnceId?: number | null;
    website?: string | null;
    eligibilityPayorId?: string | null;

    /** @format int64 */
    eligibilityAddressId?: number | null;
    claimsPayorId?: string | null;
    statusPayorId?: string | null;

    /** @format int64 */
    statusAddressId?: number | null;

    /** @format int64 */
    clearingHouseId?: number | null;

    /** @format int32 */
    inNetworkFilingLimit?: number | null;

    /** @format int32 */
    outOfNetworkFilingLimit?: number | null;

    /** @format int32 */
    correctedClaimFilingLimit?: number | null;
    acceptsSecondaryClaimsE?: boolean | null;
    acceptsCorrectedClaimsE?: boolean | null;

    /** @format int64 */
    appealAddressId?: number | null;

    /** @format int64 */
    paymentAddressId?: number | null;

    /** @format int64 */
    credentialingAddressId?: number | null;
    hasEftOption?: boolean | null;
    eftInstructions?: string | null;
    hasEraOption?: boolean | null;
    eraInstructions?: string | null;
    clearingHouse?: ClearingHousesDto;
    parentInsurance?: InsurancesDto;
    address?: AddressDto;
    credentialingAddress?: AddressDto;
    eligibilityAddress?: AddressDto;
    statusAddress?: AddressDto;
    appealAddress?: AddressDto;
    paymentAddress?: AddressDto;
    phones?: PhoneDto[] | null;
    emails?: EmailDto[] | null;
    eligibilityPhones?: PhoneDto[] | null;
    eligibilityEmails?: EmailDto[] | null;
    statusPhones?: PhoneDto[] | null;
    statusEmails?: EmailDto[] | null;
    appealPhones?: PhoneDto[] | null;
    appealEmails?: EmailDto[] | null;
    paymentPhones?: PhoneDto[] | null;
    paymentEmails?: EmailDto[] | null;
    insurancePlans?: InsurancePlansDto[] | null;
    webLinks?: WebLinksDto[] | null;
    contacts?: InsuranceContactDto[] | null;
    procedureSettings?: ProcedureSettingsDto[] | null;
    specialtyAuthorizationSettings?: AuthorizationSettingsDto[] | null;
    procedureAuthorizationSettings?: AuthorizationSettingsDto[] | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface InsurancesDtoListResponseModel {
    list: InsurancesDto[] | null;

    /** @format int64 */
    rowsCount: number;
    success: boolean;
    message: string | null;
    errors: string[] | null;
}

export interface InsurancesDtoModelResponse {
    model: InsurancesDto | null;
    success: boolean;
    message: string | null;
    errors: string[] | null;
}

export interface DtoModelResponse<DTO> {
    model?: DTO | null;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface DtoListResponseModel<DTO> {
    list?: DTO[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface LocationContectsDto {
    /** @format int32 */
    contactId?: number;

    /** @format int64 */
    locationId?: number;
    practiceContact?: PracticeContactsDto;
    location?: LocationsDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface LocationsDto {
    /** @format int32 */
    practiceId?: number | null;
    practice?: PracticesDto;
    code?: string | null;
    name?: string | null;
    alias?: string | null;
    locationType?: LocationType;

    /** @format int64 */
    placeOfServiceId?: number | null;
    npiNumber?: string | null;

    /** @format int64 */
    addressId?: number | null;
    billPatient?: boolean | null;
    placeOfService?: PlaceOfServiceDto;
    locationContacts?: LocationContectsDto[] | null;
    address?: AddressDto;
    phones?: PhoneDto[] | null;
    emails?: EmailDto[] | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface LocationsDtoListResponseModel {
    list?: LocationsDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface LocationsDtoModelResponse {
    model?: LocationsDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export enum LocationType {
    Other = "Other",
    Office = "Office",
    Hospital = "Hospital",
}

export interface ModifiersDto {
    code?: string | null;
    description?: string | null;
    causesWriteOff?: boolean;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ModifiersDtoListResponseModel {
    list?: ModifiersDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ModifiersDtoModelResponse {
    model?: ModifiersDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PhoneDto {
    /** @format tel */
    number?: string | null;
    type?: PhoneType | null;
    isPrimary?: boolean;
    objectType?: string | null;
    isDeactivated?: boolean;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export enum PhoneType {
    Home = "Home",
    Work = "Work",
    Cell = "Cell",
    Fax = "Fax",
    Other = "Other",
}

export interface PlaceOfServiceDto {
    code?: string | null;
    name?: string | null;
    isHospital?: boolean | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface PlaceOfServiceDtoListResponseModel {
    list?: PlaceOfServiceDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PlaceOfServiceDtoModelResponse {
    model?: PlaceOfServiceDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PlanExcludeBenefitDto {
    /** @format int64 */
    insurancePlanId?: number;

    /** @format int32 */
    procedureTypeId?: number;
    insurancePlan?: InsurancePlansDto;
    procedureType?: ProcedureTypesDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface PlanTypeDto {
    name?: string | null;
    description?: string | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface PlanTypeDtoListResponseModel {
    list: PlanTypeDto[] | null;

    /** @format int64 */
    rowsCount: number;
    success: boolean;
    message: string | null;
    errors: string[] | null;
}

export interface PlanTypeDtoModelResponse {
    model?: PlanTypeDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PracticeAttachmentDto {
    /** @format int32 */
    practiceId?: number;
    practice?: PracticesDto;
    /** @format int64 */
    storageObjectId?: number;
    storageObject?: StorageObject;
    comment?: string | null;
    uploadedBy?: UsersDto;
    /** @format int32 */
    uploadedById?: number | null;
    /** @format date-time */
    dateCreated?: string;
    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface StorageObject {
    /** @format int64 */
    id?: number;
    fileName?: string | null;
    /** @maxLength 5 */
    fileType?: string | null;
    attachmentType?: AttachmentType;
    /** @format int64 */
    targetId?: number;
    /** @maxLength 32 */
    objectType?: string | null;
    /** @format int64 */
    objectId?: number | null;
    /** @format int64 */
    size?: number | null;
    status?: GeneralStatus;
    key?: string | null;
    /** @format date-time */
    dateCreated?: string;
    /** @format date-time */
    dateUpdated?: string;
    /** @format date-time */
    dateDeleted?: string | null;
    isDeleted?: boolean;
}


export interface PracticeAttachmentDtoListResponseModel {
    list?: PracticeAttachmentDto[] | null;
    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PracticeAttachmentDtoModelResponse {
    model?: PracticeAttachmentDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PracticeContactsDto {
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    emailAddress?: string | null;
    practice?: PracticesDto;

    /** @format int32 */
    practiceId?: number | null;
    doctorEmails?: boolean | null;
    contactRoles?: ContactRoleDto[] | null;
    contactLocations?: LocationContectsDto[] | null;
    phones?: PhoneDto[] | null;
    emails?: EmailDto[] | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface PracticeContactsDtoListResponseModel {
    list?: PracticeContactsDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PracticeContactsDtoModelResponse {
    model?: PracticeContactsDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export class PracticesDto {
    name?: string | null;
    active?: boolean;

    /** @format int64 */
    logoStorageObjectId?: number | null;
    userPractices?: PracticeUsersDto[] | null;
    practiceContacts?: PracticeContactsDto[] | null;
    locations?: LocationsDto[] | null;
    providers?: ProvidersDto[] | null;
    profiles?: ProfilesDto[] | null;
    corporations?: CorporationsDto[] | null;

    /** @format int64 */
    addressId?: number | null;

    /** @format uri */
    website?: string | null;

    /** @format int64 */
    ehrSystemId?: number | null;

    /** @format int64 */
    phoneId?: number | null;

    /** @format int64 */
    faxId?: number | null;

    /** @format int64 */
    emailId?: number | null;

    /** @format date-time */
    dateStart?: Date | null;

    /** @format date-time */
    dateEnd?: Date | null;
    settings?: PracticeSettingsDto;
    address?: AddressDto;
    ehrSystem?: EhrDto;
    phone?: PhoneDto;
    fax?: PhoneDto;
    email?: EmailDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface PracticesDtoListResponseModel {
    list?: PracticesDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PracticesDtoModelResponse {
    model?: PracticesDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PracticeSettingsDto {
    /** @format int32 */
    id?: number | null;
    inPrmCoPayBillType?: PatientBillingType;
    inPrmCoInsBillType?: PatientBillingType;
    inPrmDedBillType?: PatientBillingType;
    inSecCoPayBillType?: PatientBillingType;
    inSecCoInsBillType?: PatientBillingType;
    inSecDedBillType?: PatientBillingType;
    outPrmCoPayBillType?: PatientBillingType;
    outPrmCoInsBillType?: PatientBillingType;
    outPrmDedBillType?: PatientBillingType;
    outSecCoPayBillType?: PatientBillingType;
    outSecCoInsBillType?: PatientBillingType;
    outSecDedBillType?: PatientBillingType;

    /** @format double */
    minimumBillAmount?: number | null;

    /** @format double */
    mimimumStatementAmount?: number | null;

    /** @format int32 */
    billDaysAfterPr100?: number | null;
    isAllowedTuPullMedicalRecords?: boolean | null;
    practice?: PracticesDto;
}

export interface PracticeSettingsDtoListResponseModel {
    list?: PracticeSettingsDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PracticeSettingsDtoModelResponse {
    model?: PracticeSettingsDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PracticeUserCompaniesDto {
    /** @format int32 */
    userPracticeId?: number;

    /** @format int32 */
    insuranceId?: number;
    insuranceName?: string | null;
    insurance?: InsurancesDto;
    userPractice?: PracticeUsersDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export enum PracticeUserRole {
    Biller = "Biller",
    Superviser = "Superviser",
    Other = "Other",
}

export interface PracticeUsersDto {
    /** @format int32 */
    userId?: number | null;

    /** @format int32 */
    practiceId?: number | null;
    userName?: string | null;
    practiceName?: string | null;
    role?: PracticeUserRole;

    /** @format date-time */
    dateStart?: string | null;

    /** @format date-time */
    dateEnd?: string | null;
    insCompany?: PracticeUserCompaniesDto[] | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface PracticeUsersDtoListResponseModel {
    list?: PracticeUsersDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface PracticeUsersDtoModelResponse {
    model?: PracticeUsersDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProcedureBundlesDto {
    /** @format int64 */
    procCodeId?: number;

    /** @format int64 */
    bundledProcCodeId?: number;
    baseProcCode?: string | null;
    bundledProcCode?: string | null;
    procCode?: string[] | null;
    type?: ProcedureBundleType;
    overwrittenType?: ProcedureBundleType;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface BaseResponseModel {
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface BooleanModelResponse {
    model?: boolean;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface BooleanNullableModelResponse {
    model?: boolean | null;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProcedureBundlesDtoListResponseModel {
    list?: ProcedureBundlesDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProcedureBundlesDtoModelResponse {
    model?: ProcedureBundlesDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export enum ProcedureBundleType {
    Allowed = "Allowed",
    NotAllowed = "NotAllowed",
    NotApplicable = "NotApplicable",
}

export interface ProcedureCodesDto {
    procCode?: string | null;
    procTypeType?: string | null;

    /** @format int32 */
    procTypeId?: number | null;
    description?: string | null;

    /** @format double */
    rvuCode?: number | null;
    isAddOn?: boolean;
    isInitialConsultation?: boolean;

    /** @format int32 */
    fromProcedureCode?: number | null;
    procedureType?: ProcedureTypesDto;
    procedureSettings?: ProcedureSettingsDto[];
    generalSettings?: GeneralSettingsDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export class CopyCloneProcCodeModel {
    constructor() {
        this.globalPeriodSettings = this.dailyLimits = this.patientRequiremnts = this.gender = this.isJustifiableWriteOff = this.overrideAllowedModifiers = this.overrideDisallowedModifiers = this.overrideRequiredModifiers = false
    }

    globalPeriodSettings: boolean;
    dailyLimits: boolean;
    patientRequiremnts: boolean;
    gender: boolean;
    isJustifiableWriteOff: boolean;
    overrideRequiredModifiers: boolean;
    overrideDisallowedModifiers: boolean;
    overrideAllowedModifiers: boolean;
    fromProcCodeId: number;
    toProcCodeId: number;
    procedureCodeName: string | null;
}

export interface ProcedureCodesDtoListResponseModel {
    list?: ProcedureCodesDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProcedureCodesDtoModelResponse {
    model?: ProcedureCodesDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProcedureModel {
    /** @format int64 */
    procedureCodeId?: number | null;

    /** @format int64 */
    placeOfServiceId?: number | null;

    /** @format int64 */
    dateOfService?: number | null;

    /** @format int32 */
    chargeAmount?: number | null;

    /** @format int32 */
    units?: number | null;
    modifierIds?: number[] | null;
    diagPointers?: number[] | null;
}

export interface ProcedureSettingsDiagnosisDto {
    /** @format int64 */
    procedureCodeId?: number | null;

    /** @format int64 */
    procedureSettingsId?: number;

    /** @format int32 */
    diagnosisCodeId?: number;
    type?: AllowanceType;

    /** @format int32 */
    sort?: number;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProcedureSettingsDiagnosisDtoListResponseModel {
    list?: ProcedureSettingsDiagnosisDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProcSettingsGroupedByCptModsModel {
    orignalGroup: ProcedureSettingsGroupedByReqModifiersCptDto;
    modifiedGroup: ProcedureSettingsGroupedByReqModifiersCptDto;
}

export interface ProcedureSettingsGroupedByReqModifiersCptDto {
    insurances?: InsurancesDto[] | null;
    practiceId?: number | null;
    procedureCodeId?: number | null;
    modifiers?: ModifiersDto[] | null;
    procedureCode?: ProcedureCodesDto | null;
}


export interface ProcedureSettingsDto {
    /** @format int32 */
    practiceId?: number | null;
    /** @format int32 */
    cptId?: number | null;
    /** @format int32 */
    insuranceId?: number | null;
    insurance?: InsurancesDto;
    isPrimary?: boolean | null;
    isJustifiableWriteOff?: boolean | null;
    isMedicareSettings?: boolean | null;
    /** @format int32 */
    globalPeriodDays?: number | null;
    globalPeriodType?: GlobalPeriodType;
    /** @format int32 */
    dailyLimit?: number | null;
    /** @format int32 */
    dailyLimitDefaultModifierId?: number | null;
    dailyLimitDefaultLineType?: DailyLimitLineType;
    /** @format int32 */
    ageMin?: number | null;
    /** @format int32 */
    ageMax?: number | null;
    gender?: GenderAllowance;
    cpt?: ProcedureCodesDto;
    /** @format int64 */
    profileContractId?: number | null;
    profileContract?: ProfileContractDto;
    diagnosisSettings?: ProcedureSettingsDiagnosisDto[] | null;
    modifierSettings?: ProcedureSettingsModifierDto[] | null;
    placeOfServiceSettings?: ProcedureSettingsPlaceOfServiceDto[] | null;

    disallowedDiagnosisSettings: number[];
    disallowedModifierSettings: number[];
    disallowedPlaceOfServiceSettings: number[];

    allowedDiagnosisSettings: number[];
    allowedModifierSettings: number[];
    allowedPlaceOfServiceSettings: number[];

    requiredDiagnosisSettings: number[];
    requiredModifierSettings: number[];
    requiredPlaceOfServiceSettings: number[];
    
    modifiers?: ModifiersDto[] | null;
    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProcedureSettingsDtoListResponseModel {
    list?: ProcedureSettingsDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProcedureSettingsDtoModelResponse {
    model?: ProcedureSettingsDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProcedureSettingsModifierDto {
    /** @format int64 */
    procedureSettingsId?: number | null;

    /** @format int64 */
    procedureCodeId?: number | null;

    /** @format int32 */
    modifierId?: number | null;
    type?: AllowanceType;

    /** @format int32 */
    sort?: number | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProcedureSettingsModifierDtoListResponseModel {
    list?: ProcedureSettingsModifierDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProcedureSettingsPlaceOfServiceDto {
    /** @format int64 */
    procedureSettingsId?: number | null;

    /** @format int64 */
    procedureCodeId?: number | null;
    type?: AllowanceType;

    /** @format int64 */
    placeOfServiceId?: number | null;
    placeOfService?: PlaceOfServiceDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProcedureSettingsPlaceOfServiceDtoListResponseModel {
    list?: ProcedureSettingsPlaceOfServiceDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProcedureTypesDto {
    procType?: string | null;
    description?: string | null;
    isEm?: boolean | null;
    isPrimaryDiagnosis?: boolean | null;

    /** @format int32 */
    globalPeriodDays?: number | null;
    globalPeriodDaysPre?: number | null;
    globalPeriodType?: string | null;
    isWro?: boolean | null;
    modifiers?: ProcedureTypeSettingModifiersDto[] | null;
    procedureCodes?: ProcedureCodesDto[] | null;

    allowedModifiers?: number[] | null;
    disallowedModifiers?: number[] | null;
    requiredModifiers?: number[] | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProcedureTypeSettingModifiersDto {
    /** @format int64 */
    procedureTypeId?: number;

    /** @format int64 */
    modifierId?: number;
    code?: string | null;
    type?: AllowanceType;
    delete?: boolean;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProfileContractPriceDto {
    /** @format int64 */
    profileContractId?: number | null;
    /** @format int32 */
    cptId?: number | null;
    profileContract?: ProfileContractDto;
    cpt?: ProcedureCodesDto;
    priceDetails?: ProfileContractPriceDetailDto[] | null;
    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProfileContractPriceDetailDto {
    profileContractPrice?: ProfileContractPriceDto;
    /** @format int64 */
    profileContractPriceId?: number | null;
    modifier?: ModifiersDto;
    /** @format int32 */
    modifierId?: number | null;
    /** @format double */
    price?: number;
    /** @format int64 */
    facilityPrice?: number;
    /** @format int64 */
    nonFacilityPrice?: number;
    id?: number | null;
    status?: GeneralStatus;
}


export interface ProfileContractPriceDtoListResponseModel {
    list?: ProfileContractPriceDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProfileContractPriceDtoModelResponse {
    model?: ProfileContractPriceDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}


export interface ProfilesDto {
    /** @format int32 */
    practiceId?: number | null;
    /** @format int32 */
    providerId?: number | null;
    /** @format int64 */
    corporationId?: number | null;
    /** @format int32 */
    insuranceId?: number | null;
    name?: string | null;
    /**
     * @minLength 2
     * @maxLength 5
     */
    code?: string | null;
    isPrimary?: boolean;

    practice?: PracticesDto;
    provider?: ProvidersDto;
    corporation?: CorporationsDto;
    insurance?: InsurancesDto;
    profileContracts?: ProfileContractDto[] | null;
    profileLocations?: ProfileLocationDto[] | null;
    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProfileLocationDto {
    /** @format int64 */
    profileId?: number;
    /** @format int64 */
    locationId?: number;
    profile?: ProfilesDto;
    location?: LocationsDto;
    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProfileContractDto {
    /** @format int64 */
    profileId?: number | null;
    /** @format int64 */
    contractBaseFeeScheduleId?: number | null;
    contractType?: ContractType;
    rateType?: ContractRateType;
    /** @format double */
    ratePercent?: number;
    /** @format date-time */
    daetStart?: string;
    /** @format date-time */
    dateEnd?: string;
    isEftSetup?: boolean;
    isEraSetup?: boolean;
    profile?: ProfilesDto;
    contractBaseFeeSchedule?: FeeSchedulesDto;
    feeScheduleOverrides?: FeeScheduleOverrideDto[] | null;
    profileContractPrices?: ProfileContractPriceDto[] | null;
    profileContractInsurancePlans?: ProfileContractInsurancePlanDto[] | null;
    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProfileContractInsurancePlanDto {
    /** @format int64 */
    profileContractId?: number | null;
    /** @format int64 */
    insurancePlanId?: number | null;
    insurancePlan?: InsurancePlansDto;
    profileContract?: ProfileContractDto;
    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}


export interface ProfilesDtoListResponseModel {
    list?: ProfilesDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProfilesDtoModelResponse {
    model?: ProfilesDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProvidersDto {
    /** @format int32 */
    practiceId?: number | null;
    practice?: PracticesDto;
    code?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    name?: string | null;
    taxId?: string | null;
    npiNumber?: string | null;
    phones?: PhoneDto[] | null;
    emails?: EmailDto[] | null;
    specialties?: ProviderSpecialtysDto[] | null;
    profiles?: ProfilesDto[] | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface ProvidersDtoListResponseModel {
    list?: ProvidersDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProvidersDtoModelResponse {
    model?: ProvidersDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ProviderSpecialtysDto {
    /** @format int32 */
    providerId?: number | null;

    /** @format int64 */
    specialtyId?: number | null;
    provider?: ProvidersDto;
    specialty?: SpecialtiesDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export enum SortType {
    Asc = "asc",
    Desc = "desc",
}

export interface SpecialtiesDto {
    name?: string | null;
    description?: string | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
    disabled?: boolean | null;
}

export interface SpecialtiesDtoListResponseModel {
    list?: SpecialtiesDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface SpecialtiesDtoModelResponse {
    model?: SpecialtiesDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export enum Subjects {
    User = "User",
    Practice = "Practice",
    Provider = "Provider",
    Scrubber = "Scrubber",
}

export interface UserAbility {
    actions?: Actions[] | null;
    subject?: Subjects;
}

export interface UserInfo {
    firstName?: string | null;
    lastName?: string | null;
    role?: UserRole;
    abilitys?: UserAbility[] | null;
}

export interface UserInfoModelResponse {
    model?: UserInfo;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export enum UserRole {
    User = "User",
    Admin = "Admin",
}

export interface UsersDto {
    name?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    type?: UserRole;
    email?: string | null;
    userPractices?: PracticeUsersDto[] | null;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface UsersDtoListResponseModel {
    list?: UsersDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface UsersDtoModelResponse {
    model?: UsersDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface WebLinksDto {
    name?: string | null;
    description?: string | null;

    /** @format uri */
    url?: string | null;
    login?: string | null;
    password?: string | null;
    comment?: string | null;

    /** @format int32 */
    insuranceId?: number | null;

    /** @format int64 */
    insurancePlanId?: number | null;
    insurance?: InsurancesDto;
    insurancePlan?: InsurancePlansDto;

    /** @format int64 */
    id?: number | null;
    status?: GeneralStatus;
}

export interface WebLinksDtoListResponseModel {
    list?: WebLinksDto[] | null;

    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface WebLinksDtoModelResponse {
    model?: WebLinksDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ScrubResultDto {
    result?: ClaimValidationResponse;
    /** @format int64 */
    visitId?: number;
    visitValidated?: ClaimValidateModel;
    status?: GeneralStatus;
    /** @format int64 */
    id?: number | null;
}

export interface ScrubResultDtoListResponseModel {
    list?: ScrubResultDto[] | null;
    /** @format int64 */
    rowsCount?: number;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}

export interface ScrubResultDtoModelResponse {
    model?: ScrubResultDto;
    success?: boolean;
    message?: string | null;
    errors?: string[] | null;
}
